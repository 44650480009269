import request from '@/utils/request'


// 查询禁用手机号记录列表
export function listDisable(query) {
  return request({
    url: '/platform/mobile-disable/list',
    method: 'get',
    params: query
  })
}

// 查询禁用手机号记录分页
export function pageDisable(query) {
  return request({
    url: '/platform/mobile-disable/page',
    method: 'get',
    params: query
  })
}

// 查询禁用手机号记录详细
export function getDisable(data) {
  return request({
    url: '/platform/mobile-disable/detail',
    method: 'get',
    params: data
  })
}

// 新增禁用手机号记录
export function addDisable(data) {
  return request({
    url: '/platform/mobile-disable/add',
    method: 'post',
    data: data
  })
}

// 修改禁用手机号记录
export function updateDisable(data) {
  return request({
    url: '/platform/mobile-disable/edit',
    method: 'post',
    data: data
  })
}

// 删除禁用手机号记录
export function delDisable(data) {
  return request({
    url: '/platform/mobile-disable/delete',
    method: 'post',
    data: data
  })
}
