<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="手機號" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手機號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵稱" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="视频id" prop="videoIds">
                <a-input v-model="queryParam.videoIds" placeholder="请输入视频id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="订单编号" prop="orderNo">
                <a-input v-model="queryParam.orderNo" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="套餐Id" prop="gearid">
                <a-input v-model="queryParam.gearid" placeholder="请输入套餐id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="購買時間" prop="createTime">
                <a-range-picker format="YYYY-MM-DD" v-model="creatRanges" @change="changeCreate"></a-range-picker>
              </a-form-item>
            </a-col>
            <!-- <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="用户id zb_user.id" prop="userId">
                  <a-input v-model="queryParam.userId" placeholder="请输入用户id zb_user.id" allow-clear/>
                </a-form-item>
              </a-col>
            </template> -->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['hot:hotRecord:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['hot:hotRecord:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['hot:hotRecord:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['hot:hotRecord:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <!-- <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['hot:hotRecord:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['hot:hotRecord:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['hot:hotRecord:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['hot:hotRecord:remove']">
            <a-icon type="delete" />删除
          </a>
        </span> -->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageHotRecord,listHotRecord, delHotRecord } from '@/api/platform/hotRecord'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'HotRecord',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      creatRanges: [],
      // 查询参数
      queryParam: {
        videoIds: null,
        orderNo: null,
        userId: null,
        startTime: null,
        endTime: null,
        gearid:null,
        userNum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '視頻id',
          dataIndex: 'videoIds',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '套餐id',
          dataIndex: 'gearid',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '購買的播放量',
          dataIndex: 'paynum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '訂單編號',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶手機號',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
      {
        title: '刷手號',
        dataIndex: 'userNum',
        ellipsis: true,
        align: 'center'
      },
        {
          title: '原有播放量',
          dataIndex: 'primarynum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '購買時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
        // ,
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询購買熱門記錄列表 */
    getList () {
      this.loading = true
     pageHotRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        videoIds: undefined,
        orderNo: undefined,
        userId: undefined,
        startTime:undefined,
        endTime: undefined,
        gearid:undefined,
        userNum :undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.creatRanges = undefined;
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delHotRecord(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    changeCreate(date, dateString) {
      console.log(dateString)
      this.queryParam.startTime = dateString[0]+" 00:00:00";
      this.queryParam.endTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('/platform/hot-record/export', {
            ...that.queryParam
          }, `購買熱門記錄_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
