<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="作品封面" prop="cover" >
        <img style="width: 100px;height: 100px;" preview="頭像"
            :src="form.cover">
      </a-form-model-item>
      <a-form-model-item label="视频" prop="video" >
        <video style="width: 200px;height: 200px;" v-if="form.video" :src="form.video" ref="myVideo"
        :poster=" form.video + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"/>
      </a-form-model-item>
      <a-form-model-item label="发布用户编号" prop="userId" >
        <a-input v-model="form.userId" placeholder="0" disabled />
      </a-form-model-item>
      <a-form-model-item label="反赞数" prop="noPraiseNum" >
        <a-input v-model="form.noPraiseNum" placeholder="0" disabled />
      </a-form-model-item>
      <a-form-model-item label="点赞数" prop="praiseNum" >
        <a-input v-model="form.praiseNum" placeholder="0" disabled />
      </a-form-model-item>
      <a-form-model-item label="播放量" prop="readNum" >
        <a-input v-model="form.readNum" placeholder="0" disabled />
      </a-form-model-item>
      <!-- <a-form-model-item label="完整播放量" prop="fullReadNum" >
        <a-input v-model="form.fullReadNum" placeholder="请输入完整播放量" />
      </a-form-model-item> -->
      <a-form-model-item label="收藏数量" prop="collectionNum" >
        <a-input v-model="form.collectionNum" placeholder="0" disabled />
      </a-form-model-item>
      <a-form-model-item label="分享数量 " prop="shareNum" >
        <a-input v-model="form.shareNum" placeholder="0" disabled />
      </a-form-model-item>
      <a-form-model-item label="评论数量" prop="discussNum" >
        <a-input v-model="form.discussNum" placeholder="0" disabled />
      </a-form-model-item>
      <!-- <a-form-model-item label="打赏总金额" prop="rewardNum" >
        <a-input v-model="form.rewardNum" placeholder="0" disabled />
      </a-form-model-item> -->
      <a-form-model-item label="视频集编号" prop="collectionId" >
        <a-input v-model="form.collectionId" placeholder="-" disabled />
      </a-form-model-item>
      <a-form-model-item label="视频集章节号" prop="collectionSection" >
        <a-input v-model="form.collectionSection" placeholder="-" disabled />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <a-input v-model="form.content" placeholder="-" disabled />
      </a-form-model-item>
      <!-- <a-form-model-item label="@的用户编号" prop="atUserIds" >
        <a-input v-model="form.atUserIds" placeholder="请输入@的用户编号" />
      </a-form-model-item>
      <a-form-model-item label="关键字" prop="keyword" >
        <a-input v-model="form.keyword" placeholder="请输入关键字" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="国家" prop="country" >
        <a-input v-model="form.country" placeholder="请输入国家" />
      </a-form-model-item>
      <a-form-model-item label="地址省" prop="provinceName" >
        <a-input v-model="form.provinceName" placeholder="请输入地址省" />
      </a-form-model-item>
      <a-form-model-item label="地址市" prop="cityName" >
        <a-input v-model="form.cityName" placeholder="请输入地址市" />
      </a-form-model-item>
      <a-form-model-item label="地址区" prop="countyName" >
        <a-input v-model="form.countyName" placeholder="请输入地址区" />
      </a-form-model-item> -->
      <a-form-model-item label="详细地址" prop="detailedAddress" >
        <a-input v-model="form.detailedAddress" placeholder="-" disabled />
      </a-form-model-item>
      <a-form-model-item label="地址经度" prop="longitude" >
        <a-input v-model="form.longitude" placeholder="-" disabled />
      </a-form-model-item>
      <a-form-model-item label="地址维度" prop="dimensionality" >
        <a-input v-model="form.dimensionality" placeholder="-" disabled />
      </a-form-model-item>
      <a-form-model-item label="权限类型" prop="permissionTypeName" >
        <a-input placeholder="-" v-model="form.permissionTypeName" disabled />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否可PK" prop="isPk" >
        <a-input v-model="form.isPk" placeholder="请输入是否可PK" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="是否支付不可PK费用" prop="noPkPay" >
        <a-input v-model="form.noPkPay" placeholder="请输入是否支付不可PK费用" />
      </a-form-model-item>
      <a-form-model-item label="是否收取PK费用" prop="isPkEarnings" >
        <a-input v-model="form.isPkEarnings" placeholder="请输入是否收取PK费用" />
      </a-form-model-item>
      <a-form-model-item label="PK收取战刀" prop="pkIntegral" >
        <a-input v-model="form.pkIntegral" placeholder="请输入PK收取战刀" />
      </a-form-model-item>
      <a-form-model-item label="是否保存自己内容带水印" prop="isWatermark" >
        <a-input v-model="form.isWatermark" placeholder="请输入是否保存自己内容带水印" />
      </a-form-model-item>
      <a-form-model-item label="是否高清发布" prop="isDistinct" >
        <a-input v-model="form.isDistinct" placeholder="请输入是否高清发布" />
      </a-form-model-item> -->
      <a-form-model-item label="是否可下载" prop="isDownload" >
        <a-input v-model="form.isDownload" placeholder="-" disabled/>
      </a-form-model-item>
      <!-- <a-form-model-item label="谁可以转发" prop="transmitType" >
      </a-form-model-item> -->
      <a-form-model-item label="谁可以评论" prop="commentType" >
        <a-input v-model="form.commentType" placeholder="-" disabled/>
      </a-form-model-item>
      <a-form-model-item label="审核状态" prop="applicationStatusName" >
        {{form.applicationStatusName}}
      </a-form-model-item>
      <a-form-model-item label="拒绝原因" prop="refusalCause" v-if="form.applicationStatus == 2">
        <a-input v-model="form.refusalCause" placeholder="-" />
      </a-form-model-item>
      <a-form-model-item label="标签" prop="labelNames" >
        <a-input v-model="form.labelNames" placeholder="-" disabled/>
      </a-form-model-item>

      <a-form-model-item label="是否购买热门" prop="isHot" >
        <a-input v-model="form.isHot" placeholder="0" disabled/>
      </a-form-model-item>
      <!-- <a-form-model-item label="剩余曝光量" prop="rate" >
        <a-input v-model="form.rate" placeholder="请输入剩余曝光量" />
      </a-form-model-item> -->
      <a-form-model-item label="状态" prop="status" >
        <a-input v-model="form.status" placeholder="0" disabled/>
      </a-form-model-item>
      <!-- <a-form-model-item label="标签编号" prop="tagId" >
        <a-input v-model="form.tagId" placeholder="请输入标签编号" />
      </a-form-model-item>
      <a-form-model-item label="是否推荐" prop="isRecommend" >
        <a-input v-model="form.isRecommend" placeholder="请输入是否推荐" />
      </a-form-model-item>
      <a-form-model-item label="播放时长" prop="playTime" >
        <a-input v-model="form.playTime" placeholder="请输入播放时长" />
      </a-form-model-item> -->
      <a-form-model-item label="链接url" prop="linkUrl" >
<!--        <a v-if="form.linkUrl" target="_blank" :href="form.linkUrl">{{ form.linkUrl }}</a>-->
        <a-input v-model="form.linkUrl" placeholder="-" />
      </a-form-model-item>
      <a-form-model-item label="链接名称" prop="linkName" >
        <a-input v-model="form.linkName" placeholder="-"/>
      </a-form-model-item>
      <a-form-model-item label="链接类型" prop="linkType" >
        <a-select v-model="form.linkTypeId" placeholder="请选择链接类型" style="width: 120px;">
          <a-select-option v-for ="(item, index) in typeList"  :key='item.id' :value='item.id' >{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="链接有效期" prop="linkEndTime" v-if="applicationStatus == 1">
        <a-date-picker format="YYYY-MM-DD" mode="date":disabledDate="disabledDate" v-model="form.linkEndTime"  placeholder="请输入链接有效期" />
      </a-form-model-item>
      <!-- <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="是否置顶 0 否1是" prop="isTop" >
        <a-input v-model="form.isTop" placeholder="请输入是否置顶 0 否1是" />
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVideo, addVideo, updateVideo } from '@/api/platform/video'
import {listType} from "@/api/problem/type"
import Editor from '@/components/Editor'
import moment from "moment";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      applicationStatus: null,
      typeList:[],
      // 表单参数
      form: {
        id: null,

        cover: null,

        coverThumb: null,

        coverWidth: null,

        coverHigh: null,

        coverSize: null,

        audioId: null,

        waterVideo: null,

        originalVideo: null,

        video: null,

        type: null,

        competitionId: null,

        userId: null,

        storeId: null,

        topicId: null,

        noPraiseNum: null,

        praiseNum: null,

        readNum: null,

        fullReadNum: null,

        collectionNum: null,

        shareNum: null,

        discussNum: null,

        rewardNum: null,

        collectionId: null,

        collectionSection: null,

        content: null,

        atUserIds: null,

        keyword: null,

        country: null,

        provinceName: null,

        cityName: null,

        countyName: null,

        detailedAddress: null,

        longitude: null,

        dimensionality: null,

        permissionType: null,

        permissionTypeName: null,

        isPk: null,

        noPkPay: null,

        isPkEarnings: null,

        pkIntegral: null,

        isWatermark: null,

        isDistinct: null,

        isDownload: null,

        transmitType: null,

        commentType: null,

        applicationStatus: 0,

        labels: null,

        refusalCause: null,

        isHot: null,

        rate: null,

        status: 0,

        tagId: null,

        isRecommend: null,

        playTime: null,

        linkUrl: null,

        linkName: null,

        linkTypeId: null,

        createTime: null,

        remark: null,

        isTop: null,
        linkEndTime:null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.listType()
  },
  methods: {
    disabledDate(current) {
      return current && current<moment().subtract(1,"d").endOf("day");
    },
    onClose () {
      this.open = false
    },
    listType(){
      listType({}).then(response => {
        this.typeList = response.data
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        cover: null,
        coverThumb: null,
        coverWidth: null,
        coverHigh: null,
        coverSize: null,
        audioId: null,
        waterVideo: null,
        originalVideo: null,
        video: null,
        type: null,
        competitionId: null,
        userId: null,
        storeId: null,
        topicId: null,
        noPraiseNum: null,
        praiseNum: null,
        readNum: null,
        fullReadNum: null,
        collectionNum: null,
        shareNum: null,
        discussNum: null,
        rewardNum: null,
        collectionId: null,
        collectionSection: null,
        content: null,
        atUserIds: null,
        keyword: null,
        country: null,
        provinceName: null,
        permissionTypeName: null,
        cityName: null,
        countyName: null,
        detailedAddress: null,
        longitude: null,
        dimensionality: null,
        permissionType: null,
        isPk: null,
        noPkPay: null,
        isPkEarnings: null,
        pkIntegral: null,
        isWatermark: null,
        isDistinct: null,
        isDownload: null,
        transmitType: null,
        commentType: null,
        applicationStatus: 0,
        labels: null,
        refusalCause: null,
        isHot: null,
        rate: null,
        status: 0,
        tagId: null,
        isRecommend: null,
        playTime: null,
        linkUrl: null,
        linkName: null,
        linkTypeId: null,
        createTime: null,
        remark: null,
        isTop: null,
        linkEndTime:null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVideo({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
        if(response.data.permissionType == 0){
          this.form.permissionTypeName = "所有人可见"
        }else if(response.data.permissionType == 1){
          this.form.permissionTypeName = "仅好友可见"
        }else if(response.data.permissionType == 2){
          this.form.permissionTypeName = "仅自己可见"
        }

        this.form.isDownload = response.data.isDownload == 0 ? '否' : '是'
        if(response.data.commentType == 0){
          this.form.commentType = "所有人"
        }else if(response.data.commentType == 1){
          this.form.commentType = "仅好友"
        }else if(response.data.commentType == 2){
          this.form.commentType = "仅自己"
        }
        if(response.data.applicationStatus == -1){
          this.form.applicationStatusName = "草稿"
        }else if(response.data.applicationStatus == 0){
          this.form.applicationStatusName = "待审核"
        }else if(response.data.applicationStatus == 1){
          this.form.applicationStatusName = "已审核"
        }else if(response.data.applicationStatus == 2){
          this.form.applicationStatusName = "已拒绝"
        }

        this.form.isHot = this.form.isHot == 0 ? '否' : '是'
        this.form.status = this.form.status == 0 ? '正常' : '下架'

        this.applicationStatus = response.data.applicationStatus
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVideo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVideo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>