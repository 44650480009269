<template>
  <div class="ant-modal prompt" v-show="visible" style="z-index: 1002">
    <div class="ant-modal-content" v-show="visible">
      <div class="ant-modal-body">
        <h3>{{ title }}</h3>
        <a-input type="textarea" v-model="inputValue" @keyup.enter="submit"/>
        <a-button style="float: right;margin-top: 20px;" class="ant-btn" @click="closeForm">取消</a-button>
        <a-button style="float: right;margin-top: 20px;margin-right: 10px;" class="ant-btn ant-btn-primary" @click="submit">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: ''
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.inputValue);
      //this.inputValue = '';
    },
    closeForm(){
      this.$emit('cancel');

    },
  }
};
</script>

<style scoped>
.prompt {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  z-index: 1002;
}

.prompt-content {
  /* 样式按需定义 */
  background-color: #fff;
  padding: 32px;
  border-radius: 4px;
}
</style>
