<template>
  <div class="map-picker">
    <div class="search-box">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="搜索位置..."
        @keyup.enter="searchLocation"
      />
      <button @click="searchLocation">搜索</button>
    </div>

    <div id="map" ref="mapContainer" class="map-container"></div>

    <div class="selected-location" v-if="selectedLocation">
      <p>已选择位置：</p>
      <p>纬度：{{ selectedLocation.lat }}</p>
      <p>经度：{{ selectedLocation.lng }}</p>
      <p>国家：{{ selectedLocation.country }}</p>
      <p>省/州：{{ selectedLocation.province }}</p>
      <p>城市：{{ selectedLocation.city }}</p>
      <p>区县：{{ selectedLocation.district }}</p>
      <p>详细地址：{{ selectedLocation.address }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleMapPicker',

  data() {
    return {
      map: null,
      marker: null,
      searchQuery: '',
      selectedLocation: null,
      geocoder: null
    }
  },

  mounted() {
    this.initializeMap()
  },

  methods: {
    initializeMap() {
      // 加载谷歌地图
      const mapOptions = {
        center: { lat: 22.396428, lng: 114.109497 }, // 默认中心点（这里以香港为例）
        zoom: 11,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }

      this.map = new google.maps.Map(this.$refs.mapContainer, mapOptions)
      this.geocoder = new google.maps.Geocoder()

      // 添加点击事件监听器
      this.map.addListener('click', (event) => {
        this.placeMarker(event.latLng)
      })
    },

    placeMarker(location) {
      if (this.marker) {
        this.marker.setMap(null)
      }

      this.marker = new google.maps.Marker({
        position: location,
        map: this.map
      })

      // 获取详细地址信息
      this.geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const addressComponents = results[0].address_components

          // 创建不包含邮政编码的地址
          const formattedAddress = results[0].formatted_address.replace(/,?\s*\d{5,}(-\d{4})?/g, '')

          const addressTwo = formattedAddress.replace("邮政编码:","")

          const locationInfo = {
            lat: location.lat(),
            lng: location.lng(),
            address: addressTwo,
            country: '',
            province: '',
            city: '',
            district: ''
          }

          // 解析地址组件
          addressComponents.forEach(component => {
            const types = component.types
            // 跳过邮政编码
            if (types.includes('postal_code')) return

            if (types.includes('country')) {
              locationInfo.country = component.long_name
            }
            else if (types.includes('administrative_area_level_1')) {
              locationInfo.province = component.long_name
            }
            else if (types.includes('locality') || types.includes('administrative_area_level_2')) {
              locationInfo.city = component.long_name
            }
            else if (types.includes('sublocality_level_1') || types.includes('administrative_area_level_3')) {
              locationInfo.district = component.long_name
            }
          })

          this.selectedLocation = locationInfo
          this.$emit('location-selected', this.selectedLocation)
        }
      })
    },

    searchLocation() {
      if (!this.searchQuery) return

      this.geocoder.geocode({ address: this.searchQuery }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location
          this.map.setCenter(location)
          this.placeMarker(location)
        } else {
          alert('未找到该位置')
        }
      })
    }
  }
}
</script>

<style scoped>
.map-picker {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.search-box {
  margin-bottom: 10px;
}

.search-box input {
  padding: 8px;
  width: 300px;
  margin-right: 10px;
}

.map-container {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.selected-location {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
}
</style>