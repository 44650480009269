<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="手機號" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入用戶手機號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵稱" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用戶昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="收支類型" prop="inOut">
                <a-select v-model="queryParam.inOut">
                  <a-select-option :value="1">
                    收入
                  </a-select-option>
                  <a-select-option :value="-1">
                    支出
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="時間">
                <a-range-picker
                  :placeholder="['开始時間', '结束時間']"
                  @change="onDateChange"
                  format="YYYY-MM-DD"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport">
          <a-icon type="download" />導出
        </a-button>
        <a-button type="primary" @click="handleTotalExport">
          <a-icon type="download" />匯總
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageIntegralBill,listIntegralBill, delIntegralBill } from '@/api/platform/integralBill'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'IntegralBill',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        userId: null,
        inOut: null,
        type: null,
        resourceId: null,
        integralNum: null,
        message: null,
        userNum:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手機號',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收支類型',
          dataIndex: 'inOut',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == -1) {
              return "支出"
            }
            if (value == 1) {
              return "收入"
            }
          }
        },
        {
          title: '類型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "充值"
            }
            if (value == 2) {
              return "完成任务"
            }
            if (value == 3) {
              return "完成任务"
            }
            if (value == 4) {
              return "参与PK"
            }
            if (value == 8) {
              return "会员购买"
            }
            if (value == 9) {
              return "拒绝开启PK费用"
            }
            if (value == 10) {
              return "直播"
            }
            if (value == 12) {
              return "转发任务"
            }
            if (value == 15) {
              return "购买曝光"
            }
            if (value == 99) {
              return "平臺更新"
            }
          }
        },
        {
          title: '產品Id',
          dataIndex: 'productId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '米幣数量',
          dataIndex: 'integralNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '說明',
          dataIndex: 'message',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '新增時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢米幣流水业务列表 */
    getList () {
      this.loading = true
     pageIntegralBill(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onDateChange(value, dateString) {
      console.log(dateString[0],dateString[1]);
      this.queryParam.startTime=dateString[0];
      this.queryParam.endTime=dateString[1];
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        inOut: undefined,
        type: undefined,
        resourceId: undefined,
        integralNum: undefined,
        message: undefined,
        userNum:undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delIntegralBill(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    handleTotalExport(){

      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/integral-bill/exportSummary', {
            ...that.queryParam
          }, `米幣流水匯總_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/integral-bill/export', {
            ...that.queryParam
          }, `米幣流水业务_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
