<template>
    <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <delete-form
        ref="deleteForm"
        @ok="getList1"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!-- <a-divider type="vertical"/> -->
          <a @click="$refs.deleteForm.handleUpdate(record, undefined)">
            <a-icon type="delete" />刪除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    </a-drawer>
</template>

<script>
import { pageUserComment,listUserComment, delUserComment } from '@/api/platform/userComment'
import { tableMixin } from '@/store/table-mixin'
import DeleteForm from '@/views/platform/userComment/modules/DeleteForm'

export default {
  name: 'ShowComment',
  components: {
    DeleteForm
  },
  mixins: [tableMixin],
  data () {
    return {
      formTitle: '評論列表',
      open: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        byUserId: null,
        resourceType: null,
        resourceId: null,
        commentId: null,
        replyId: null,
        userId: null,
        content: null,
        replyCommentUserId: null,
        replyNum: null,
        likeNum: null,
        type: null,
        isDisable: null,
        atUserIds: null,
        atUserNames: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '作品類型',
        //   dataIndex: 'resourceType',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value, record) {
        //     if (value == 1) {
        //       return "視頻"
        //     }
        //     if (value == 2) {
        //       return "赛事"
        //     }
        //     if (value == 3) {
        //       return "PK"
        //     }
        //   }
        // },
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '評論內容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '回復數',
          dataIndex: 'replyNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '點贊數',
          dataIndex: 'likeNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '差評數',
          dataIndex: 'noLikeNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '類型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "評論"
            }
            if (value == 2) {
              return "回复"
            }
          }
        },
        {
          title: '插入時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    getList1() {
      this.getList(this.queryParam.resourceId)
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 查詢作品-评价回复列表 */
    getList (id) {
      console.log(id)
      this.formType = 1
      this.open = true
      this.loading = true
      this.queryParam.resourceId = id
      pageUserComment(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList(this.queryParam.resourceId)
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        byUserId: undefined,
        resourceType: undefined,
        resourceId: this.queryParam.resourceId,
        commentId: undefined,
        replyId: undefined,
        userId: undefined,
        content: undefined,
        replyCommentUserId: undefined,
        replyNum: undefined,
        likeNum: undefined,
        type: undefined,
        isDisable: undefined,
        atUserIds: undefined,
        atUserNames: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.resourceId)
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.resourceId)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUserComment(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList(that.queryParam.resourceId)
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/user-comment/export', {
            ...that.queryParam
          }, `作品-评价回复_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
