<template>
  <Transition name="modal-fade">
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-header">
            <h1>{{ title }}</h1>
          </div>
          <div class="modal-body">
            {{ message }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="onClick(1)">確認</button>
            <button class="btn btn-secondary" @click="onClick(2)">不作處理</button>
            <button class="btn btn-cancel" @click="onClick(3)">取消</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {updateUserReport} from "@/api/platform/userReport";
import request from "@/utils/request";

export default {
  props: {
    title: String,
    message: String
  },
  data() {
    return {
      isVisible: false,
      recordData: null,
      that2: null
    };
  },
  methods: {
    onClick(response) {
      let that = this;
      if(response == 1){  //確認-修改狀態+處理

        updateUserReport(that.recordData).then(response => {

          if(that.recordData.processStatus == 2 && that.recordData.resourceType == 1) {  //当举报的是視頻时
            let data = []
            data.push({"id": that.recordData.resourceId});
            //调用下架視頻业务
            request({
              url: '/platform/video/changeStatus?id='+that.recordData.resourceId+"&noticeStatus=1&remark=用戶舉報,管理員下架",
              method: 'post',
              data: data
            }).then(() => {
              that.that2.open = false
              that.that2.$emit('ok')
              that.isVisible = false;
            })

          }else if(that.recordData.processStatus == 2 && that.recordData.resourceType == 2) { //如果是评论
            let data = []
            data.push({"id": that.recordData.resourceId});
            //调用刪除評論业务
            request({
              url: '/platform/user-comment/delete',
              method: 'post',
              data: data
            }).then(() => {
              that.that2.open = false
              that.that2.$emit('ok')
              that.isVisible = false;
            })
          }else if(that.recordData.processStatus == 2 && that.recordData.resourceType == 3) { //用戶
            let data1 = {"userId": that.recordData.resourceId, "status":0};
            //调用封禁用戶业务
            request({
              url: '/user/user/handleBan',
              method: 'post',
              params: data1
            }).then(() => {
              that.that2.open = false
              that.that2.$emit('ok')
              that.isVisible = false;
            })
          }else if(that.recordData.processStatus == 2 && that.recordData.resourceType == 5){ //直播
            //调用关闭直播间业务
            request({
              url: '/platform/direct-seeding/close?id='+that.recordData.resourceId,
              method: 'get',
              data: {id: that.recordData.resourceId}
            }).then(() => {
              that.that2.open = false
              that.that2.$emit('ok')
              that.isVisible = false;
            })
          }else{
            that.that2.open = false
            that.that2.$emit('ok')
            that.isVisible = false;
          }

        }).finally(() => {
          // that.submitLoading = false
        })

      }else if(response == 2){   //不作處理-只修改狀態，不處理
        updateUserReport(that.recordData).then(response => {
          that.$message.success('修改成功', 3)
        }).finally(() => {
         // that.submitLoading = false
          that.isVisible = false;
          that.that2.open = false
          that.that2.$emit('ok')
        })
      }else{  //取消-取消這次的狀態變更的動作
          that.$message.success('取消成功', 3)
          that.isVisible = false
          that.that2.open = false
          that.that2.$emit('ok')
      }
    },
    show(title,message,record,that2) {
      this.title = title;
      this.message = message;
      this.isVisible = true;
      this.recordData = record;
      this.that2 = that2;
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper {
  max-width: 90%;
  width: 500px;
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.modal-header h1 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
}

.modal-body {
  padding: 1.5rem;
  color: #4b5563;
  line-height: 1.5;
}

.modal-footer {
  padding: 1rem 1.5rem;
  background-color: #f9fafb;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.btn-primary {
  background-color: #3b82f6;
  color: white;
}

.btn-primary:hover {
  background-color: #2563eb;
}

.btn-secondary {
  background-color: #9ca3af;
  color: white;
}

.btn-secondary:hover {
  background-color: #6b7280;
}

.btn-cancel {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #374151;
}

.btn-cancel:hover {
  background-color: #f3f4f6;
}

/* 过渡动画 */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.modal-fade-enter-to,
.modal-fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>