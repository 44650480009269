import request from '@/utils/request'


// 查询首页广告浏览列表
export function listBrowse(query) {
  return request({
    url: '/platform/slideshow-browse/list',
    method: 'get',
    params: query
  })
}

// 查询首页广告浏览分页
export function pageBrowse(query) {
  return request({
    url: '/platform/slideshow-browse/page',
    method: 'get',
    params: query
  })
}

// 查询首页广告浏览详细
export function getBrowse(data) {
  return request({
    url: '/platform/slideshow-browse/detail',
    method: 'get',
    params: data
  })
}

// 新增首页广告浏览
export function addBrowse(data) {
  return request({
    url: '/platform/slideshow-browse/add',
    method: 'post',
    data: data
  })
}

// 修改首页广告浏览
export function updateBrowse(data) {
  return request({
    url: '/platform/slideshow-browse/edit',
    method: 'post',
    data: data
  })
}

// 删除首页广告浏览
export function delBrowse(data) {
  return request({
    url: '/platform/slideshow-browse/delete',
    method: 'post',
    data: data
  })
}

// 查询广告投放详细
export function getProccSubtotal(data) {
  return request({
    url: '/platform/slideshow-browse/getProccSubtotal',
    method: 'get',
    params: data
  })
}
