<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
       <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="投放开始时间" prop="beginTime">
                <a-range-picker format="YYYY-MM-DD" v-model="startRanges" @change="changeStart"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="投放结束时间" prop="endTime">
                <a-range-picker format="YYYY-MM-DD" v-model="endRanges" @change="changeEnd"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete">
          <a-icon type="delete" />刪除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <browse
        ref="browse"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <img style="width: 48px;height: 48px;" preview="頭像" @click="imgShow(record.image)" :src="record.image" v-if="record.image != null && record.image != ''" slot="image" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"/>
          <a @click="handleStatus(record,1)" v-if="record.status == 0">
            下架
          </a>
          <a @click="handleStatus(record,0)" v-if="record.status == 1">
            上架
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)">
            <a-icon type="delete" />刪除
          </a>
          <a-divider type="vertical"v-if="record.skipType==3"/>
           <a @click="handlePreview(record)" v-if="record.skipType==3">
            <a-icon type="eye" />预览
           </a>
           <a-divider type="vertical" />
          <a @click="$refs.browse.getList(record.id)">
            <a-icon type="search" />執行記錄
          </a>
        </span>
      </a-table>
      <!-- 预览 -->
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="600" :height="'100%'"
               style="top: 0; padding: 0;">
        <div v-html="previewContent"></div>
      </a-modal>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSlideshow,listSlideshow, delSlideshow,changeStatus } from '@/api/platform/slideshow'
import CreateForm from './modules/CreateForm'
import Browse from './modules/browse'
import { tableMixin } from '@/store/table-mixin'
import {imgShow} from "@/utils/ruoyi";

export default {
  name: 'Slideshow',
  components: {
    CreateForm,
    Browse
  },
  mixins: [tableMixin],
  data () {
    return {
      previewVisible: false,
      previewContent: null,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      startRanges:[],
      endRanges:[],
      // 查詢参数
      queryParam: {
        placeType: null,
        skipType: null,
        business: null,
        resourceId: null,
        sort: null,
        beginStartTime: null,
        beginEndTime: null,
        endStartTime: null,
        endEndTime:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '图片',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "image"}
        },
        // {
        //   title: '位置',
        //   dataIndex: 'placeType',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value) {
        //     if (value == 1){
        //       return "赛事"
        //     }
        //     if (value == 2){
        //       return "刷手商城"
        //     }
        //     if (value == 3){
        //       return "商品分类"
        //     }
        //   }
        // },
        {
          title: '跳转類型',
          dataIndex: 'skipType',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "无"
            }
            if (value == 2){
              return "跳转链接"
            }
            if (value == 3){
              return "站内链接"
            }
            if (value == 4){
              return "跳转"
            }
          }
        },
        // {
        //   title: '排序',
        //   dataIndex: 'sort',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '投放开始时间',
          dataIndex: 'beginTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '投放结束时间',
          dataIndex: 'endTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '建立時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    imgShow(imgUrl){
      imgShow(imgUrl);
    },
    handlePreview(record){
      this.previewContent = record.remark
      this.previewVisible = true
    },
    handleCancel() {
      this.previewContent = null
      this.previewVisible = false
    },
    /** 查詢轮播图列表 */
    getList () {
      this.loading = true
     pageSlideshow(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleStatus(row,status){
      var that = this
      let tit = status == 1 ? '下架': '上架'
      this.$confirm({
        title: '确认'+tit+'所选中数据?',
        content: '当前选中编号为' + row.id + '的数据',
        onOk () {
          return changeStatus(row.id)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },

    changeStart(date, dateString) {
      console.log(dateString)
      this.queryParam.beginStartTime = dateString[0]+" 00:00:00";
      this.queryParam.beginEndTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },


    changeEnd(date, dateString) {
      console.log(dateString)
      this.queryParam.endStartTime = dateString[0]+" 00:00:00";
      this.queryParam.endEndTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        placeType: undefined,
        skipType: undefined,
        business: undefined,
        resourceId: undefined,
        sort: undefined,
        beginStartTime: undefined,
        beginEndTime: undefined,
        endStartTime: undefined,
        endEndTime:undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.startRanges=[]
      this.endRanges = []
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSlideshow(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/slideshow/export', {
            ...that.queryParam
          }, `轮播图_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
