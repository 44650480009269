<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-row>

        <a-col>
          <a-form-model-item label="用戶昵稱">
            {{ formTwo.userName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报的用戶昵稱">
            {{ formTwo.toUserName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="刷手號" prop="userNum">
            {{ formTwo.userNum }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="類型">
            {{ formTwo.typeName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报理由">
            {{ formTwo.content }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报内容">
            {{ formTwo.resourceData }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="舉報图片" prop="avatar">
         <span  v-if="formTwo.imgUrls != null && form.imgUrls != ''">
           <img @click="imgShow(imgUrl)" style="width: 100px;height: 100px;margin-left: 5px" preview="舉報图片" v-for="(imgUrl, index) in formTwo.imgUrls.split(',')" :src="imgUrl" v-if="formTwo.imgUrls != null && formTwo.imgUrls != ''"/>
          </span>
            <span v-else>
            暂无图片
          </span>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报類型">
            <span v-if="formTwo.resourceType == 1">視頻</span>
            <span v-if="formTwo.resourceType == 2">評論</span>
            <span v-if="formTwo.resourceType == 3">用戶</span>
            <span v-if="formTwo.resourceType == 4">赛事</span>
            <span v-if="formTwo.resourceType == 5">直播</span>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="视频" v-if="formTwo.resourceType == 1 &&formTwo.video">
            <div class="imgbox margin-center" @click="showDetail(formTwo.video)">
              <div class="videomask">
                <a-icon class="icon" type="play-circle" />
              </div>
              <img  style="width: 48px;height: 48px;"  :src="formTwo.video + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                    class="response">
            </div>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="直播間" v-if="formTwo.resourceType == 5">
            <a-button  type="primary" @click="$refs.showDirect.getList(formTwo.resourceId)" v-if=" form.resourceType == 5">
              所屬直播間
            </a-button>
          </a-form-model-item>
        </a-col>

<!--        <a-col>
          <a-form-model-item label="处理狀態">
            <span v-if="formTwo.processStatus == 1">待处理</span>
            <span v-if="formTwo.processStatus == 2">已处理</span>
            <span v-if="formTwo.processStatus == 3">暂不处理</span>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="处理结果">
            {{ formTwo.processResult }}
          </a-form-model-item>
        </a-col>-->

        <a-col>
          <a-form-model-item label="舉報時間">
            <span>{{ formTwo.createTime }}</span>
          </a-form-model-item>
        </a-col>
      </a-row>



      <a-form-model-item label="处理狀態" prop="processStatus" >
        <a-select v-model="form.processStatus" placeholder="请选择处理狀態">
          <a-select-option :value="2">已处理</a-select-option>
          <a-select-option :value="3">暂不处理</a-select-option>
          <a-select-option :value="4">無效</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否通知用戶"  prop="noticeStatus">
        <a-select v-model="form.noticeStatus" placeholder="请选择通知狀態" @change="changeStatus">
          <a-select-option :value="0">不通知</a-select-option>
          <a-select-option :value="1">通知</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="舉報选项"  prop="noticeStatus" v-show="showVer">
        <a-select v-model="form.auditTypeId" placeholder="请选择下架原因选项">
          <a-select-option v-for="item in auditTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="处理结果" prop="processResult" v-show="showVer">
        <a-input v-model="form.processResult" placeholder="请输入处理结果" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            變更狀態
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>

    <!-- 視頻预览播放 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="600" :height="'100%'"
             style="top: 0; padding: 0;">
      <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
      <video  :src="previewImage" ref="myVideo" autoPlay  width="560" height="600"
              :poster=" previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
    </a-modal>

    <three-model ref="model" @ok="getList"/>

    <modal-image-preview :image-url="currentImageUrl" v-if="currentImageUrl" />

    <show-direct ref="showDirect" />

  </a-drawer>

</template>

<script>
import { getUserReport, addUserReport, updateUserReport } from '@/api/platform/userReport'
import Editor from '@/components/Editor'
import {delTable} from "@/api/tool/gen";
import request from "@/utils/request";
import {listAuditType} from "@/api/platform/video";
import ThreeModel from './ThreeModel';
import ShowDirect from "@/views/platform/userReport/modules/ShowDirect"

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    ThreeModel,
    ShowDirect
  },
  data () {
    return {
      currentImageUrl:null,
      submitLoading: false,
      auditTypeList: [],
      previewVisible: false,
      previewImage: '',
      showVer: false,
      resourceType: null,
      resourceId: null,
      formTitle: '',
      formTwo:null,
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        resourceId: null,

        resourceType: null,

        processStatus: 0,

        processResult: null,

        createTime: null,

        remark: null,

        noticeStatus: null,

        auditTypeId: null


      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用戶id，外键{user.id}不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '举报類型：1-政治反动内容 2-低俗色情内容 3-垃圾广告信息 4-恶意攻击谩骂 5-侵权抄袭冒充 6-其他违规不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    showDetail(video){
      this.previewImage = video;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false
    },
    onClose () {
      this.open = false
    },
    auditTypeInit(){
      listAuditType({"place":7}).then(response => {
        this.auditTypeList = response.data;
      })
    },
    changeStatus(status){
      if(status == 0){
        this.showVer = false;
      }
      if(status ==1){
        this.showVer = true;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        resourceId: null,
        resourceType: null,
        processStatus: 0,
        processResult: null,
        auditTypeId: null,
        createTime: null,
        remark: null,
        noticeStatus:null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.formTwo = row
      this.resourceType = row.resourceType;
      this.resourceId = row.resourceId;

      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserReport({"id":id}).then(response => {
        this.form = response.data
        this.form.processStatus = 2
        this.form.noticeStatus = 0;
        this.open = true
        this.formTitle = '修改'

        this.auditTypeInit();
      })
    },
    submitForm: function () {

      let that = this;
      that.$refs.form.validate(valid => {
        if (valid) {
       //   that.submitLoading = true
          let title = "";
          let concat = "";
          if (that.form.processStatus == 2 && that.resourceType == 1) {  //当举报的是視頻时
            title = "是否同时下架此視頻?";
          } else if (that.form.processStatus == 2 && that.resourceType == 2) { //如果是评论
            title = "是否同时刪除此評論?";
          } else if (that.form.processStatus == 2 && that.resourceType == 3) { //用戶
            title = "是否同時封禁此用戶?";
          } else if (that.form.processStatus == 2 && that.resourceType == 5) { //直播
            title = "是否同时關閉直播間?";
          } else {
            title = "确定进行此操作嘛?";
          }
          that.$refs.model.show(title, concat, that.form,that);
        }
      })

     // let that = this;

    },
    /** 提交按钮 */
    submitForm2: function () {
      let that = this;
      that.$refs.form.validate(valid => {
        if (valid) {
          that.submitLoading = true
          if (that.form.id !== undefined && that.form.id !== null) {
            updateUserReport(that.form).then(response => {
              that.$message.success(
                '修改成功',
                3
              )
              if(that.form.processStatus == 2 && that.resourceType == 1) {  //当举报的是視頻时
                let data = []
                //data.push({"id": that.resourceId});
                that.$confirm({
                  title: '是否同时下架此視頻?',
                  content: '',
                  onOk() {
                    //调用下架視頻业务
                    request({
                      url: '/platform/video/changeStatus?id='+that.resourceId+"&noticeStatus=1&remark=用戶舉報,管理員下架",
                      method: 'post',
                      data: data
                    }).then(() => {
                      that.open = false
                      that.$emit('ok')
                    })
                  },
                  onCancel() {
                    that.open = false
                    that.$emit('ok')
                  }
                })
              }else if(that.form.processStatus == 2 && that.resourceType == 2) { //如果是评论
                let data = []
                data.push({"id": that.resourceId});
                that.$confirm({
                  title: '是否同时刪除此評論?',
                  content: '',
                  onOk() {
                    //调用刪除評論业务
                    request({
                      url: '/platform/user-comment/delete',
                      method: 'post',
                      data: data
                    }).then(() => {
                      that.open = false
                      that.$emit('ok')
                    })
                  },
                  onCancel() {
                    that.open = false
                    that.$emit('ok')
                  }
                })
              }else if(that.form.processStatus == 2 && that.resourceType == 3) { //用戶
                let data1 = {"userId": that.resourceId, "status":0};
                console.log(data1)
                that.$confirm({
                  title: '是否同時封禁此用戶?',
                  content: '',
                  onOk() {
                    //调用封禁用戶业务
                    request({
                      url: '/user/user/handleBan',
                      method: 'post',
                      params: data1
                    }).then(() => {
                      that.open = false
                      that.$emit('ok')
                    })
                  },
                  onCancel() {
                    that.open = false
                    that.$emit('ok')
                  }
                })
              }else if(that.form.processStatus == 2 && that.resourceType == 5){ //直播
                that.$confirm({
                  title: '是否同时關閉直播間?',
                  content: '',
                  onOk() {
                    //调用关闭直播间业务
                    request({
                      url: '/platform/direct-seeding/close?id='+that.resourceId,
                      method: 'get',
                      data: {id: that.resourceId}
                    }).then(() => {
                      that.open = false
                      that.$emit('ok')
                    })
                  },
                  onCancel() {
                    that.open = false
                    that.$emit('ok')
                  }
                })
              }else{
                that.open = false
                that.$emit('ok')
              }
            }).finally(() => {
              that.submitLoading = false
            })
          } else {
            addUserReport(that.form).then(response => {
              that.$message.success(
                '新增成功',
                3
              )
              that.open = false
              that.$emit('ok')
            }).finally(() => {
              that.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 50px;
  height: 50px;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  line-height: 55px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 18px !important;
    color: #fff !important;
  }
}
</style>