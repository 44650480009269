import request from '@/utils/request'

// 查询用户信息分页
export function pageUser(query) {
  return request({
    url: '/user/user/page',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUserInvite(query) {
  return request({
    url: '/user/user/userInvitePage',
    method: 'get',
    params: query
  })
}

// 查询虚拟用户信息分页
export function pageVirtualUser(query) {
  return request({
    url: '/user/user/virtualUserPage',
    method: 'get',
    params: query
  })
}

// 生成虚拟账号
export function virtualUsers(query) {
  return request({
    url: '/user/user/virtualUsers',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUserOpen(query) {
  return request({
    url: '/user/user/openPage',
    method: 'get',
    params: query
  })
}

export function handleBan(query){
  return request({
    url: '/user/user/handleBan',
    method: 'post',
    params: query
  })
}

export function updtaeIntegral(query){
  return request({
    url: '/user/user/updtaeIntegral',
    method: 'post',
    params: query
  })
}

export function unbind(query){
  return request({
    url: '/user/user/unbind',
    method: 'post',
    params: query
  })
}


export function getUser(data) {
  return request({
    url: '/user/user/detail',
    method: 'get',
    params: data
  })
}

export function changeMobile(query){
  return request({
    url: '/user/user/changeMobile',
    method: 'post',
    params: query
  })
}

export function changePwd(query){
  return request({
    url: '/user/user/changePwd',
    method: 'post',
    params: query
  })
}

export function changeTeenagerPwd(query){
  return request({
    url: '/user/user/changeTeenagerPwd',
    method: 'post',
    params: query
  })
}

export function disableUser(query){
  return request({
    url: '/user/user/disableUser',
    method: 'post',
    data: query
  })
}

export function handleLive(query){
  return request({
    url: '/user/user/handleLive',
    method: 'post',
    params: query
  })
}
