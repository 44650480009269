<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用戶昵稱" prop="userId">
                <a-input v-model="queryParam.userName" placeholder="请输入用戶昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="文案内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入文案内容" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="狀態" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择狀態" allow-clear>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">下架</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="審核狀態" prop="applicationStatus">
                <a-select v-model="queryParam.applicationStatus" placeholder="请选择審核狀態" allow-clear>
                  <a-select-option value="0">待審核</a-select-option>
                  <a-select-option value="1">通過</a-select-option>
                  <a-select-option value="2">不通過</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- 增加修改 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport">
          <a-icon type="download"/>
          導出
        </a-button>
      </div>
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <off-shelf-form
        ref="offshelfForm"
        @ok="getList"
      />
      <!-- 增加修改 -->
      <show-content
        ref="showContent"
      />

      <show-comment
        ref="showComment"
      />

      <show-hot-record
        ref="showHotRecord"
      />
      <shangjiaf-form ref="shangjiaForm"
                        @ok="getList"/>

      <pay-hot-form ref="payHotForm"
                      @ok="getList"/>

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered">

        <span slot="video" slot-scope="text,record">
              <div class="imgbox margin-center" @click="showDetail(record.video)">
                <div class="videomask">
                  <a-icon class="icon" type="play-circle"/>
                </div>
                <img style="width: 48px;height: 48px;"
                     :src="record.video + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                     class="response">
              </div>
        </span>

        <img style="width: 48px;height: 48px;" preview="封面" :src="record.cover" @click="imgShow(record.cover)"
             v-if="record.cover != null && record.cover != ''" slot="cover" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.applicationStatus == 0">
            审核
          </a>
          <a-divider type="vertical" v-if="record.applicationStatus == 0"/>
          <a @click="$refs.showContent.handleUpdate(record, undefined)">查看内容</a>
          <a-divider type="vertical"/>
          <a-dropdown>
             <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
             <a-menu slot="overlay">
               <a-menu-item>
                  <a @click="handleDelete(record)">刪除</a>
               </a-menu-item>
               <a-menu-item v-if="record.status == 0">
                  <a @click="$refs.offshelfForm.handleUpdate(record, undefined)" v-if="record.status == 0">下架</a>
               </a-menu-item>
               <a-menu-item v-if="record.status == 1">
                  <a @click="$refs.shangjiaForm.handleUpdate(record, undefined)" v-if="record.status == 1">上架</a>
               </a-menu-item>
                <a-menu-item>
                  <a @click="$refs.showComment.getList(record.id)">評論</a>
               </a-menu-item>
                <a-menu-item>
                  <a @click="$refs.showHotRecord.getList(record.id)">熱門記錄</a>
               </a-menu-item>
               <a-menu-item v-if="record.isHot == 0">
                  <a @click="$refs.payHotForm.handleUpdate(record,undefined)"  v-if="record.isHot == 0">加入熱門</a>
               </a-menu-item>
               <a-menu-item v-if="record.isComment == 1">
                  <a-popconfirm title="是否確認關閉評論區" @confirm="() => handleComment(record.id,0)">關閉評論區</a-popconfirm>
               </a-menu-item>
                 <a-menu-item v-if="record.isComment == 0">
                 <a-popconfirm title="是否確認開啓評論區" @confirm="() => handleComment(record.id,1)">開啓評論區</a-popconfirm>
               </a-menu-item>
             </a-menu>
          </a-dropdown>
        </span>
      </a-table>

      <!-- 視頻预览播放 -->
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="600" :height="'100%'"
               style="top: 0; padding: 0;">
        <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
        <video :src="previewImage" ref="myVideo" autoPlay width="560" height="600"
               :poster=" previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>

      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageVideo, delVideo, changeStatus,handleComment} from '@/api/platform/video'
import CreateForm from './modules/CreateForm'
import OffShelfForm from './modules/OffShelfForm'
import ShowContent from './modules/ShowContent'
import ShowHotRecord from './modules/ShowHotRecord'

import ShangjiafForm from './modules/ShangjiaForm'
import PayHotForm from './modules/PayHotForm'

import {tableMixin} from '@/store/table-mixin'
import Video from '@/views/video/modules/Video'
import ShowComment from './modules/ShowComment'

export default {
  name: 'Video',
  components: {
    CreateForm,
    ShowContent,
    ShowComment,
    Video,
    ShowHotRecord,
    OffShelfForm,
    ShangjiafForm,
    PayHotForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      previewVisible: false,
      previewImage: '',
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        id: null,
        cover: null,
        coverThumb: null,
        coverWidth: null,
        coverHigh: null,
        coverSize: null,
        audioId: null,
        video: null,
        type: null,
        competitionId: null,
        userId: null,
        storeId: null,
        praiseNum: null,
        readNum: null,
        collectionNum: null,
        shareNum: null,
        rewardNum: null,
        topicId: null,
        content: null,
        atUserIds: null,
        keyword: null,
        country: null,
        provinceName: null,
        cityName: null,
        countyName: null,
        detailedAddress: null,
        longitude: null,
        dimensionality: null,
        permissionType: null,
        isPk: null,
        noPkPay: null,
        isPkEarnings: null,
        pkIntegral: null,
        isWatermark: null,
        isDistinct: null,
        isDownload: null,
        transmitType: null,
        commentType: null,
        applicationStatus: null,
        refusalCause: null,
        rate: null,
        isRecommend: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '作品封面',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "cover"}
        },
        {
          title: '視頻',
          dataIndex: 'video',
          ellipsis: true,
          align: 'center',
          width: 60,
          scopedSlots: {customRender: "video"}
        },
        /* {
           title: '赛事標題',
           dataIndex: 'competitionTitle',
           ellipsis: true,
           align: 'center'
         },*/
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        /*  {
            title: '店铺名稱',
            dataIndex: 'storeName',
            ellipsis: true,
            align: 'center'
          },*/
        // {
        //   title: '打赏总金額',
        //   dataIndex: 'rewardNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '话题標題',
        //   dataIndex: 'topicTitle',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '熱門積分',
          dataIndex: 'hotRank',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '文案内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '審核狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 0) {
              return "審核中"
            }
            if (value == 1) {
              return "已同意"
            }
            if (value == 2) {
              return "已拒絕"
            }
          }
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value) {
              return value
            } else {
              return "-"
            }
          }
        },
        {
          title: '狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "下架"
            }
            if (value == 0) {
              return "正常"
            }
          }
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    imgShow(imageUrl) {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        //创建弹出层
        const previewContatiner = document.createElement('div')
        previewContatiner.style.position = 'fixed'
        previewContatiner.style.top = 0
        previewContatiner.style.bottom = 0
        previewContatiner.style.left = 0
        previewContatiner.style.right = 0
        previewContatiner.style.zIndex = 9999
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContatiner.style.display = 'flex'
        previewContatiner.style.justifyContent = 'center'
        previewContatiner.style.alignItems = 'center'
        document.body.appendChild(previewContatiner)
        //在弹出层增加图片
        const previewImage = document.createElement('img')
        previewImage.src = imageUrl
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewImage.style.zIndex = 9999
        previewContatiner.appendChild(previewImage)
        //点击弹出层，关闭预览
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner)
        })
      }
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    /** 查詢視頻-信息列表 */
    getList() {
      this.loading = true
      pageVideo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    showDetail(video) {
      this.previewImage = video;
      const myVideo = this.$refs.myVideo;
      this.previewVisible = true;
      //this.$refs.myVideo.play();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false
    },
    handleComment(id,status){
      var that = this;
      let param = {"videoId":id,"isComment":status};
      handleComment(param).then((res)=>{
        if (res.success) {
          that.$message.success(res.message);
          that.getList()
        } else {
          that.$message.warning(res.message);
        }
        that.getList()
      });

    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cover: undefined,
        coverThumb: undefined,
        coverWidth: undefined,
        coverHigh: undefined,
        coverSize: undefined,
        audioId: undefined,
        video: undefined,
        type: undefined,
        competitionId: undefined,
        userId: undefined,
        storeId: undefined,
        praiseNum: undefined,
        readNum: undefined,
        collectionNum: undefined,
        shareNum: undefined,
        rewardNum: undefined,
        topicId: undefined,
        content: undefined,
        atUserIds: undefined,
        keyword: undefined,
        country: undefined,
        provinceName: undefined,
        cityName: undefined,
        countyName: undefined,
        detailedAddress: undefined,
        longitude: undefined,
        dimensionality: undefined,
        permissionType: undefined,
        isPk: undefined,
        noPkPay: undefined,
        isPkEarnings: undefined,
        pkIntegral: undefined,
        isWatermark: undefined,
        isDistinct: undefined,
        isDownload: undefined,
        transmitType: undefined,
        commentType: undefined,
        applicationStatus: undefined,
        refusalCause: undefined,
        rate: undefined,
        isRecommend: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delVideo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    handleStatus(row, status) {
      var that = this
      let tit = status == 1 ? '下架' : '上架'
      this.$confirm({
        title: '确认' + tit + '所选中数据?',
        content: '当前选中编号为' + row.id + '的数据',
        onOk() {
          return changeStatus(row.id)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('platform/video/export', {
            ...that.queryParam
          }, `視頻-信息_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>

<style lang="less">

.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 50px;
  height: 50px;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  line-height: 55px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 18px !important;
    color: #fff !important;
  }
}

.ant-modal-close-x {
  height: 36px !important;
  width: 36px !important;
  line-height: 36px !important;
}

#fullscreen-video {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  display: block;
  max-width: 100%;
  max-height: 100%;
}
</style>