import request from '@/utils/request'


// 查询视频-信息列表
export function listVideo(query) {
    return request({
        url: '/platform/video/list',
        method: 'get',
        params: query
    })
}

// 查询视频-信息分页
export function pageVideo(query) {
    return request({
        url: '/platform/video/page',
        method: 'get',
        params: query
    })
}

// 查询视频-信息详细
export function getVideo(data) {
    return request({
        url: '/platform/video/detail',
        method: 'get',
        params: data
    })
}

// 新增视频-信息
export function addVideo(data) {
    return request({
        url: '/platform/video/add',
        method: 'post',
        data: data
    })
}

// 修改视频-信息
export function updateVideo(data) {
    return request({
        url: '/platform/video/apply',
        method: 'post',
        data: data
    })
}

// 删除视频-信息
export function delVideo(data) {
    return request({
        url: '/platform/video/delete',
        method: 'post',
        data: data
    })
}

export function changeStatus(id) {
    return request({
        url: '/platform/video/changeStatus?id=' + id,
        method: 'post'
    })
}

export function changeStatus1(id,remark,status,auditTypeId) {
  let url = '/platform/video/changeStatus?id=' + id;
  if(remark != null){
    url = url + '&remark=' + remark;
  }
  if(status != null){
    url = url + '&noticeStatus=' + status;
  }
  if(auditTypeId != null){
    url = url + '&auditTypeId=' + auditTypeId;
  }
  return request({
    url: url,
    method: 'post'
  })
}

export function payHot(videoIds,packageId) {
  return request({
    url: '/platform/video/payHot?videoIds=' + videoIds+'&packageId='+packageId,
    method: 'post'
  })
}
export function handleComment(query){
  return request({
    url: '/platform/video/isComment',
    method: 'post',
    params: query
  })
}

// 查询视频审核类型
export function listAuditType(query) {
  return request({
    url: '/platform/video-audit-reject-type/list',
    method: 'get',
    params: query
  })
}

