<template>
   <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>

    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="類型" prop="type">
                <a-select v-model="queryParam.type" placeholder="请选择類型" allow-clear>
                  <a-select-option :value="0">浏览</a-select-option>
<!--                  <a-select-option :value="1">点击</a-select-option>-->
<!--                  <a-select-option :value="2">下載</a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['adv:platform:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['adv:platform:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['adv:platform:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport">-->
<!--          <a-icon type="download" />匯出-->
<!--        </a-button>-->
      </div>
     <div>
      <span>浏览小计:</span><span style="margin-left: 10px;color: red;font-weight: bold;">{{ this.subtotal.viewPrice }}</span>
<!--      <span style="margin-left:10px;">点击小计:</span><span style="margin-left: 10px;color: red;font-weight: bold;">{{ this.subtotal.clickPrice }}</span>-->
      <span style="margin-left:10px;">总计:</span><span style="margin-left: 10px;color: red;font-weight: bold;"> {{ this.subtotal.totalPrice }}</span>
     </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

  </a-drawer>
</template>

<script>
import { pageBrowse,listBrowse, delBrowse,getProccSubtotal } from '@/api/platform/slideshowBrowse'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'browse',
  components: {

  },
  mixins: [tableMixin],
  data () {
    return {
      formTitle: '執行記錄',
      // 1增加,2修改
      formType: 1,
      open: false,
      list: [],
      subtotal: {},
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userNum: null,
        browseId: null,
        type: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
      {
          title: '刷手号',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "浏览"
            }else if (value == 1) {
              return "点击"
            }else if (value == 2) {
              return "下载"
            }else {
              return "-"
            }
          }
        },
        {
          title: '浏览时间段',
          dataIndex: 'time',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '浏览时间',
          dataIndex: 'browseTime',
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    //  this.getProccSubtotal()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 修改按钮操作 */
    getProccSubtotal () {
      getProccSubtotal({"browseId":this.queryParam.browseId}).then(response => {
        this.subtotal = response.data
      })
    },
    /** 查询用户-浏览列表 */
    getList (browseId) {
      this.formType = 1
      this.open = true
      this.loading = true
      this.queryParam.browseId = browseId
      pageBrowse(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
        this.getProccSubtotal()
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList(this.queryParam.browseId)
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        browseId: this.queryParam.browseId,
        type: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.browseId)
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.browseId)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBrowse(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList(this.queryParam.browseId)
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('/platform/slideshow-browse/export', {
            ...that.queryParam
          }, `用户-浏览_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
