<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="創建時間" prop="createTime">
                <a-range-picker format="YYYY-MM-DD" v-model="creatRanges" @change="changeCreate"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="直播類型" prop="directStatus">
                <a-select v-model="queryParam.liveBroadcast" placeholder="请选择直播類型">
                  <a-select-option :value="0">普通直播</a-select-option>
                  <a-select-option :value="1">團播</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="直播狀態" prop="directStatus">
                <a-select v-model="queryParam.directStatus" placeholder="请选择直播狀態">
                  <a-select-option :value="0">直播中</a-select-option>
                  <a-select-option :value="1">已结束</a-select-option>
                  <a-select-option :value="2">强制关闭</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport">
          <a-icon type="download" />導出
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <prompt title="请输入关闭原因" @submit="handleSubmit"  :visible="showDialog" @cancel="handleCancel"/>

      <close-direct-form
        ref="closeDirectForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <img style="width: 48px;height: 48px;" preview="頭像" :src="record.image" v-if="record.image != null && record.image != ''" slot="image" slot-scope="text, record" @click="imgShow(record.image)">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a @click="handleDelete(record)" v-if="record.directStatus == 0">
            关闭直播
          </a>-->
            <a v-if="record.directStatus == 0" @click="$refs.closeDirectForm.handleUpdate(record, undefined)">关闭直播</a>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageDirectSeeding,  closeSeeding} from '@/api/platform/directSeeding'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import  Prompt from '@/components/Prompt/index'
import CloseDirectForm from './modules/CloseDirectForm'


export default {
  name: 'DirectSeeding',
  components: {
    CreateForm,
    Prompt,
    CloseDirectForm
  },
  mixins: [tableMixin],
  data () {
    return {
      showDialog:false,
      deleteId:null,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      creatRanges:[],
      queryParam: {
        image: null,
        userId: null,
        totalNumber: null,
        directStatus: null,
        isFront: null,
        microphone: null,
        camera: null,
        createBy: null,
        updateBy: null,
        delFlag: null,
        closeTime: null,
        isBeauty: null,
        pkStatus: null,
        newFans: null,
        likedNum: null,
        giftNum: null,
        showId: null,
        directId: null,
        directPkId: null,
        recordVideoCover: null,
        recordVideo: null,
        recordVideoTime: null,
        userNum : null,
        createBeginTime : null,
        createEndTime : null,
        liveBroadcast: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '直播间ID',
          dataIndex: 'showId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '標題',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '標籤',
          dataIndex: 'interestTags',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '封面',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "image"}
        },
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '定位位置',
          dataIndex: 'location',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '直播類型 ',
          dataIndex: 'liveBroadcast',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "普通直播"
            }
            if (value == 1) {
              return "團播"
            }
          }
        },
        {
          title: '直播狀態 ',
          dataIndex: 'directStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "直播中"
            }
            if (value == 1) {
              return "已结束"
            }
            if(value == 2){
              return "强制关闭"
            }
          }
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '直播时长',
          dataIndex: 'closeTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    imgShow(imageUrl) {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        //创建弹出层
        const previewContatiner = document.createElement('div')
        previewContatiner.style.position = 'fixed'
        previewContatiner.style.top = 0
        previewContatiner.style.bottom = 0
        previewContatiner.style.left = 0
        previewContatiner.style.right = 0
        previewContatiner.style.zIndex = 9999
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContatiner.style.display = 'flex'
        previewContatiner.style.justifyContent = 'center'
        previewContatiner.style.alignItems = 'center'
        document.body.appendChild(previewContatiner)
        //在弹出层增加图片
        const previewImage = document.createElement('img')
        previewImage.src = imageUrl
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewImage.style.zIndex = 9999
        previewContatiner.appendChild(previewImage)
        //点击弹出层，关闭预览
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner)
        })
      }
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    /** 查詢直播记录列表 */
    getList () {
      this.loading = true
     pageDirectSeeding(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        image: undefined,
        userId: undefined,
        totalNumber: undefined,
        directStatus: undefined,
        isFront: undefined,
        microphone: undefined,
        camera: undefined,
        createBy: undefined,
        updateBy: undefined,
        delFlag: undefined,
        closeTime: undefined,
        isBeauty: undefined,
        pkStatus: undefined,
        newFans: undefined,
        likedNum: undefined,
        giftNum: undefined,
        showId: undefined,
        directId: undefined,
        directPkId: undefined,
        recordVideoCover: undefined,
        recordVideo: undefined,
        recordVideoTime: undefined,
        userNum : undefined,
        liveBroadcast: undefined,
        createBeginTime : undefined,
        createEndTime : undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.creatRanges = []
      this.handleQuery()
    },
    changeCreate(date, dateString) {
      console.log(dateString)
      this.queryParam.createBeginTime = dateString[0]+" 00:00:00";
      this.queryParam.createEndTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleCancel(){
      this.showDialog = false
    },
    handleSubmit(value) {
      console.log('Submitted value:', value);
      debugger
      if(!value) {
        this.$message.warn(
          '请输入关闭原因',
          3
        )
        return;
      }
        debugger
        let data = {
                "id":this.deleteId,
                "remark":value
              }
        var that = this
       return closeSeeding(data)
              .then(() => {
                this.deleteId = null;
                that.showDialog = false;
                that.getList()
                that.$message.success(
                  '关播成功',
                  3
                )
            })
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      // this.$confirm({
      //   title: '确认关闭直播吗?',
      //   content: '当前选中直播号为' + row.showId,
      //   onOk () {
      //     let data = {
      //       "id":row.id
      //     }
      //     return closeSeeding(data)
      //       .then(() => {
      //         that.getList()
      //         that.$message.success(
      //           '关播成功',
      //           3
      //         )
      //     })
      //   },
      //   onCancel () {
      //     //取消清空刪除ids列表
      //     that.ids = []
      //   }
      // })
      this.showDialog = true;
      this.deleteId = row.id;
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/direct-seeding/export', {
            ...that.queryParam
          }, `直播记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
