<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <!--      <div class="table-page-search-wrapper">-->
      <!--        <a-form layout="inline">-->
      <!--          <a-row :gutter="48">-->
      <!--            <a-col :md="8" :sm="24">-->
      <!--              <a-form-item label="内容" prop="content">-->
      <!--                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear/>-->
      <!--              </a-form-item>-->
      <!--            </a-col>-->
      <!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
      <!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
      <!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>-->
      <!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
      <!--              </span>-->
      <!--            </a-col>-->
      <!--          </a-row>-->
      <!--        </a-form>-->
      <!--      </div>-->

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <img style="width: 48px;height: 48px;" preview="頭像" :src="record.image"
             v-if="record.image != null && record.image != ''" slot="image" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleDelete(record)" v-if="record.directStatus == 0">
            关闭直播
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <prompt title="请输入关闭原因" @submit="handleSubmit" :visible="showDialog"/>
  </a-drawer>
</template>

<script>
import {pageDirectSeeding, closeSeeding} from '@/api/platform/directSeeding'
import {tableMixin} from '@/store/table-mixin'
import Prompt from '@/components/Prompt/index'

export default {
  name: 'ShowDirect',
  components: {
    Prompt

  },
  mixins: [tableMixin],
  data() {
    return {
      formTitle: '直播列表',
      open: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      showDialog: false,
      deleteId: null,
      queryParam: {
        image: null,
        userId: null,
        totalNumber: null,
        directStatus: null,
        isFront: null,
        microphone: null,
        camera: null,
        createBy: null,
        updateBy: null,
        delFlag: null,
        closeTime: null,
        isBeauty: null,
        pkStatus: null,
        newFans: null,
        likedNum: null,
        giftNum: null,
        showId: null,
        directId: null,
        directPkId: null,
        recordVideoCover: null,
        recordVideo: null,
        recordVideoTime: null,
        userNum: null,
        createBeginTime: null,
        createEndTime: null,
        liveBroadcast: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '直播间ID',
          dataIndex: 'showId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '標題',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '標籤',
          dataIndex: 'interestTags',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '封面',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "image"}
        },
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '定位位置',
          dataIndex: 'location',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '直播類型 ',
          dataIndex: 'liveBroadcast',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "普通直播"
            }
            if (value == 1) {
              return "團播"
            }
          }
        },
        {
          title: '直播狀態 ',
          dataIndex: 'directStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "直播中"
            }
            if (value == 1) {
              return "已结束"
            }
            if (value == 2) {
              return "强制关闭"
            }
          }
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '直播时长',
          dataIndex: 'closeTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    // this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    /** 查詢作品-评价回复列表 */
    getList(id) {
      console.log(id)
      this.formType = 1
      this.open = true
      this.loading = true
      this.queryParam.directId = id
      pageDirectSeeding(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList(this.queryParam.directId)
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        image: undefined,
        userId: undefined,
        totalNumber: undefined,
        directStatus: undefined,
        isFront: undefined,
        microphone: undefined,
        camera: undefined,
        createBy: undefined,
        updateBy: undefined,
        delFlag: undefined,
        closeTime: undefined,
        isBeauty: undefined,
        pkStatus: undefined,
        newFans: undefined,
        likedNum: undefined,
        giftNum: undefined,
        showId: undefined,
        directId: undefined,
        directPkId: undefined,
        recordVideoCover: undefined,
        recordVideo: undefined,
        recordVideoTime: undefined,
        userNum: undefined,
        liveBroadcast: undefined,
        createBeginTime: undefined,
        createEndTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.directId)
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.directId)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSubmit(value) {
      console.log('Submitted value:', value);
      debugger
      if (!value) {
        this.$message.warn(
          '请输入关闭原因',
          3
        )
        return;
      }
      debugger
      let data = {
        "id": this.deleteId,
        "remark": value
      }
      var that = this
      return closeSeeding(data)
        .then(() => {
          this.deleteId = null;
          that.showDialog = false;
          that.getList(that.queryParam.directId)
          that.$message.success(
            '关播成功',
            3
          )
        })
    },
    /** 刪除按钮操作 */
    handleDelete(row) {
      var that = this
      // this.$confirm({
      //   title: '确认关闭直播吗?',
      //   content: '当前选中直播号为' + row.showId,
      //   onOk () {
      //     let data = {
      //       "id":row.id
      //     }
      //     return closeSeeding(data)
      //       .then(() => {
      //         that.getList()
      //         that.$message.success(
      //           '关播成功',
      //           3
      //         )
      //     })
      //   },
      //   onCancel () {
      //     //取消清空刪除ids列表
      //     that.ids = []
      //   }
      // })
      this.showDialog = true;
      this.deleteId = row.id;
    }
  }
}
</script>
