import request from '@/utils/request'


// 查询热门充值列表
export function listHotRecharge(query) {
    return request({
        url: '/platform/hot-recharge/list',
        method: 'get',
        params: query
    })
}

// 查询热门充值分页
export function pageHotRecharge(query) {
    return request({
        url: '/platform/hot-recharge/page',
        method: 'get',
        params: query
    })
}

// 查询热门充值详细
export function getHotRecharge(data) {
    return request({
        url: '/platform/hot-recharge/detail',
        method: 'get',
        params: data
    })
}

// 新增热门充值
export function addHotRecharge(data) {
    return request({
        url: '/platform/hot-recharge/add',
        method: 'post',
        data: data
    })
}

// 修改热门充值
export function updateHotRecharge(data) {
    return request({
        url: '/platform/hot-record/edit',
        method: 'post',
        data: data
    })
}

// 删除热门充值
export function delHotRecharge(data) {
    return request({
        url: '/platform/hot-recharge/delete',
        method: 'post',
        data: data
    })
}