<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="下架原因选项"  prop="auditTypeId">
        <a-select v-model="form.auditTypeId" placeholder="请选择下架原因选项">
          <a-select-option v-for="item in auditTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="關閉原因" prop="remark">
        <a-input v-model="form.remark" type="textarea" allow-clear placeholder="请输關閉原因" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getVideo, changeStatus1, updateVideo, listAuditType} from '@/api/platform/video'
import Editor from '@/components/Editor'
import {closeSeeding} from "@/api/platform/directSeeding";

export default {
  name: 'OffShelfForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      showVer: false,
      submitLoading: false,
      formTitle: '',
      auditTypeList: [],
      // 表单参数
      form: {
        id: null,
        remark: null,
        auditTypeId: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    auditTypeInit(){
      listAuditType({"place":5}).then(response => {
        this.auditTypeList = response.data;
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        remark: null,
        auditTypeId: null
      }
     /* this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    changeStatus(status){
      if(status == 0){
        this.showVer = false;
      }
      if(status ==1){
        this.showVer = true;
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.form.id = id
      this.open = true
      this.formTitle = '關閉直播'
      this.auditTypeInit();
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            var that = this
            let data = {
              "id":that.form.id,
              "remark":that.form.remark,
              "auditTypeId":that.form.auditTypeId
            }
            closeSeeding(data)
              .then(() => {
                that.$message.success('关播成功', 3)
                that.open = false
                that.$emit('ok')
              })

            // changeStatus1(this.form.id,this.form.refusalCause,this.form.noticeStatus,this.form.auditTypeId).then(response => {
            //   this.$message.success(
            //     '下架成功',
            //     3
            //   )
            //   this.open = false
            //   this.$emit('ok')
            // }).finally(() => {
            //   this.submitLoading = false
            // })
           }

        } else {
          return false
        }
      })
    }
  }
}
</script>
